import React from "react"
import PropTypes from "prop-types"
import { Controller, Scene } from "react-scrollmagic"

import Subheader from "./subheader"
import Footer from "./footer"

const SubpageLayout = ({ children, customClasses, backLocation, title }) => {
  return (
    <div className={`page ${customClasses || ""}`}>
      <div className="container-fluid">
        <Controller>
          <Scene duration={80} offset={165}>
            {progress => (
              <>
                <Subheader
                  backLocation={backLocation}
                  title={title}
                  progress={progress}
                />
                <main>{children}</main>
                <Footer />
              </>
            )}
          </Scene>
        </Controller>
      </div>
    </div>
  )
}

SubpageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  customClasses: PropTypes.string,
  backLocation: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default SubpageLayout
