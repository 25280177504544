import { navigate } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { Timeline, Tween } from "react-gsap"

const Subheader = ({ backLocation, title, progress }) => {
  let backTitle
  if (backLocation !== "/") {
    const backLocationParts = backLocation.split("/")
    backTitle = backLocationParts.pop()
    if (backTitle === "") backTitle = backLocationParts.pop()
    backTitle = backTitle[0].toUpperCase() + backTitle.substring(1)
  }

  return (
    <header className="row">
      <nav className="col-xs-12">
        <button
          onClick={() => {
            navigate(backLocation)
          }}
        >
          <img src="/img/arrow-left.svg" alt="" />
          {backTitle ? backTitle : "Rivic Visuals"}
        </button>
        <Timeline totalProgress={progress} paused>
          <Tween
            from={{ transform: "translateY(42px)" }}
            to={{ transform: "translateY(0)" }}
            ease="Linear.easeNone"
          >
            <h4>{title}</h4>
          </Tween>
        </Timeline>
      </nav>
    </header>
  )
}

Subheader.propTypes = {
  backLocation: PropTypes.string.isRequired,
  title: PropTypes.string,
  progress: PropTypes.number,
}

export default Subheader
