import React from "react"
import { graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"

import SEO from "../components/seo"
import SubpageLayout from "../components/subpagelayout"

export default ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  let { backLocation } = location.state ?? { backLocation: "/" }
  backLocation = backLocation ?? "/"

  return (
    <SubpageLayout
      title={post.frontmatter.title}
      backLocation={backLocation}
      customClasses="single-post"
    >
      <SEO title={post.frontmatter.title} />
      <article>
        <section id="intro">
          <h2>{post.frontmatter.title}</h2>
          <h5>{post.frontmatter.summary}</h5>
          <div className="meta">
            <div className="meta-set meta-set-group">
              {post.frontmatter.roles.map((r, i) => (
                <p key={i}>{r}</p>
              ))}
              <h6>Verantwortlichkeiten</h6>
            </div>

            <div className="meta-set">
              <p>{post.frontmatter.duration}</p>
              <h6>Projektdauer</h6>
            </div>

            {post.frontmatter.budget && (
              <div className="meta-set">
                <p>{post.frontmatter.budget}</p>
                <h6>Design-Budget</h6>
              </div>
            )}
          </div>
        </section>

        <section className="col-xs-12 description">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </section>

        <section id="contact" className="row">
          <div className="col-xs-12 col-sm-9 col-sm-offset-1">
            <h3>
              Zu jedem Projekt lässt sich noch viel mehr erzählen.
              <br />
              Melden Sie sich, falls Sie Interesse haben.
            </h3>
            <span className="big mail animated">
              <a href="mailto:luka@rivic.at">luka@rivic.at</a>
            </span>
            <span className="big phone animated">
              +43&thinsp;660&thinsp;575&thinsp;46&thinsp;45
            </span>
          </div>
        </section>
      </article>
      <nav
        className="row"
        style={{
          display: `flex`,
          flexWrap: `no-wrap`,
          justifyContent: `space-between`,
          alignItems: `center`,
          listStyle: `none`,
          padding: `0`,
        }}
      >
        <span
          style={{
            // flexBasis: `calc(100% - 64px)`,
            flexBasis: `100%`,
            flexShrink: 1,
            maxWidth: 800,
            height: 8,
            marginRight: 50,
            backgroundImage: `url(/img/pattern-diag.png)`,
          }}
        ></span>
        {previous && (
          <TransitionLink
            to={previous.fields.slug}
            rel="prev"
            title={previous.frontmatter.title}
            state={{ backLocation }}
            entry={{
              length: 0.75,
              trigger: () => {
                document.querySelector("main").classList.add("movePrev")
              },
            }}
            exit={{
              length: 0.5,
              trigger: () => {
                document.querySelector("main").classList.add("movePrev")
              },
            }}
          >
            <img
              src="/img/arrow-left.svg"
              alt="Pfeil nach Links"
              style={{ objectFit: `contain` }}
            />
          </TransitionLink>
        )}
        {next && (
          <TransitionLink
            to={next.fields.slug}
            rel="next"
            title={next.frontmatter.title}
            state={{ backLocation }}
            style={{
              marginLeft: 32,
            }}
            entry={{
              length: 0.75,
              trigger: () => {
                document
                  .querySelectorAll("main")
                  .forEach(e => e.classList.remove("movePrev"))
              },
            }}
            exit={{
              length: 0.5,
              trigger: () => {
                document
                  .querySelectorAll("main")
                  .forEach(e => e.classList.remove("movePrev"))
              },
            }}
          >
            <img
              src="/img/arrow-right.svg"
              alt="Pfeil nach Rechts"
              style={{ objectFit: `contain` }}
            />
          </TransitionLink>
        )}
      </nav>
    </SubpageLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        summary
        duration
        budget
        roles
      }
    }
  }
`
